<template>
    <div class='center_top'>
        <div class="box">
            <div class="card">
                场所在线率
                <div>
                    <el-tooltip  effect="light" placement="right" popper-class="atooltip">
                        <div slot="content" style="text-align: center;font-size: 14px;">
                            自2024年7月15日起，累计数据<br />
                            计算方式：每日在线空气设备之和 / 空气设备总数之和<br />
                        </div>

                        <span v-if="airDeviceAverageOnlineRate>100">{{ 100 }} %</span>
                        <span v-else>{{ airDeviceAverageOnlineRate }} %</span>
                        
                    </el-tooltip>
                    <img v-if="airDeviceAverageOnlineRate>100" src="../../../../assets/newDistrict/tip.png" alt="">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
// import countTo from 'vue-count-to';

export default {
    // components: { countTo },
    props: {
        airDeviceAverageOnlineRate: {
            type: Object
        }
    },
    data() {
        return {
            count: {
                siteCount: null,
                areaCount: null,
                riskTotalCount: null,
            },
        };
    },
    created() {
    },
    mounted() {

    },
    computed: {
    },
    methods: {

    }
};
</script>

<style scoped lang="scss">
.center_top {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    .box {
        display: flex;
        justify-content: space-between;
        width: 100%;

        //   margin-bottom: 20px;
        .card {
            // width: 100%;
            width: 308px;
            height: 54px;
            margin: auto;
            margin-top: 20px;
            background: url('~@/assets/infant/onlineRate.png') no-repeat;
            background-size: 100% 100%;
            color: #ffffff;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0 10px;

            span {
                font-size: 30px;
                font-weight: bold;
                color: #3AFFE4;
            }
            >div{
                display: flex;
                align-items: center;
                img{
                    width: 15px;
                    height: 15px;
                    margin-left: 20px;
                }
            }
        }
    }

}
</style>