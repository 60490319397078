<template>
  <div class='infant_right'>
    <ul>
      <li>
        <p><img src="../../../../assets/infant/socket.png" alt=""></p>
        <div>
          <h3>{{percentData.boardDeviceStatusRate?(percentData.boardDeviceStatusRate*100).toFixed(2):0}} %</h3>
          <p>插座在线率</p>
        </div>
      </li>
      <li>
        <p><img src="../../../../assets/infant/camera.png" alt=""></p>
        <div>
          <h3>{{percentData.cameraDeviceStatusRate?(percentData.cameraDeviceStatusRate*100).toFixed(2):0}} %</h3>
          <p>摄像头在线率</p>
        </div>
      </li>
      <li>
        <p><img src="../../../../assets/infant/self.png" alt=""></p>
        <div>
          <h3>100.00 %</h3>
          <p>场馆自查完成率</p>
        </div>
      </li>
      <!-- <li>
        <p><img src="../../../../assets/infant/pass.png" alt=""></p>
        <div>
          <h3>{{percentData.airQualifiedRate?percentData.airQualifiedRate*100:0}} %</h3>
          <p>空气实时合格率</p>
        </div>
      </li>
      <li>
        <p><img src="../../../../assets/infant/disinfect.png" alt=""></p>
        <div>
          <h3>{{percentData.yesterdayDisinfectRate?percentData.yesterdayDisinfectRate*100:0}} %</h3>
          <p>昨日消毒数量</p>
        </div>
      </li>
      
      
      -->
      <li>
        <p><img src="../../../../assets/infant/sensor.png" alt=""></p>
        <div>
          <h3>{{percentData.airDeviceStatusRate?(percentData.airDeviceStatusRate*100).toFixed(2):0}} %</h3>
          <p>空气传感器在线率</p>
        </div>
      </li>
    </ul>
    <div class="img_box">
      <div class="swiper swiper_img" v-if="imgList.length>0">
        <div class="swiper-wrapper">
          <p v-for="(el, index) in imgList" class="swiper-slide" :key="index">
            <img :src="el" alt="">
          </p>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>

import Swiper, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css'
Swiper.use([Autoplay, Pagination])
export default {
  // components: { countTo },
  data () {
    return {
      imgList: [
        require('../../../../assets/infant/宣传图.png'),
        require('../../../../assets/infant/入场测温.png'),
        // require('../../../../assets/infant/扫码管理.png'),
        require('../../../../assets/infant/空气监测.png'),
        require('../../../../assets/infant/消毒管理.png'),
        require('../../../../assets/infant/水质管理.png')
      ],
      percentData: {},
      dayTime: null
    };
  },
  created () {
  },
  mounted () {
    this.initSwiper()
  },
  methods: {
    init (val) {
      this.percentData = val
      // const nowTime = this.$moment(new Date(), 'YYYY-MM-DD')
      // const startTime = this.$moment('2022-11-1', 'YYYY-MM-DD')
      // this.dayTime = this.$moment(nowTime).diff(startTime, 'day')
      // console.log('this.dayTime :>> ', this.dayTime);
      // console.log(this.$moment(this.$moment().format('YYYY-MM-DD')).diff(this.$moment('2022-11-1').format('YYYY-MM-DD'), 'day'));
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.imgList && this.imgList.length > 1) {
          new Swiper('.swiper_img', {
            autoplay: {
              delay: 5000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            slidesPerView: 'auto',
            loop: true,
            speed: 3000,
            pagination: {
              el: '.swiper-pagination'
            }
          })
        }
        if (this.imgList && this.imgList.length <= 1) {
          new Swiper('.swiper_img', {
            slidesPerView: 'auto',
            speed: 3000
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.infant_right {
  flex-shrink: 0;
  width: 514px;
  // text-align: center;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-bottom: 40px;
      width: 50%;
      display: flex;
      align-items: center;
      > p {
        width: 101px;
        height: 73px;
        background: url("../../../../assets/infant/center_bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 30%;
          margin-top: -10px;
        }
      }
      > div {
        text-align: center;
        h3 {
          font-size: 30px;
          font-weight: bold;
          color: #08fcff;
          margin-bottom: 11px;
          line-height: 1;
        }
        p {
          font-size: 18px;
          color: #ffffff;
          line-height: 1;
        }
      }
    }
  }
  .img_box {
    background: url("../../../../assets/infant/swiper_bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    width: 485px;
    box-sizing: border-box;
    padding: 15px;
    height: 723px;
    // border-radius: 10px 10px 11px 11px;
    .swiper_img {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 11px 11px;
      .swiper-wrapper {
        width: 100%;
        height: 100%;
        .swiper-slide {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 11px 11px;
          }
        }
      }
    }
  }
  // background: url("../../../../assets/city/lefttop.png") no-repeat;
}
</style>
