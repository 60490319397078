<template>
  <div class="cityheader">
    <div class="center">
      <p>{{areaName}}</p>
      <p>婴浴无忧安全监管平台</p>
    </div>
    <div class="time">
      <span>
        <img src="@/assets/city/time.png" alt="">
      </span>
      <p>
        <span>{{this.$moment().format('YYYY/MM/DD')}}</span>
        <span>{{this.$moment(time).format('HH:mm')}}</span>
      </p>
    </div>
    <div class="right">
      <p>{{name}}</p>
      <div class="goback" @click="goback">
        <span>返回平台</span>
      </div>
    </div>
  </div>
</template>

<script>
var interval
// import AMap from 'AMap'
import { mapGetters } from 'vuex'
// import { environment } from '@/utils/config.js'
export default {
  data () {
    return {
      time: this.$moment(),
      areaName: ''
    };
  },
  created () {
    interval = setInterval(() => this.time = new Date(), 1000);

  },
  mounted () {
    // this.initWeather()
  },
  destroyed () {
    clearInterval(interval)
  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    init (areaName) {
      this.areaName = areaName
    },
    goback () {
      // console.log('1111 :>> ', 1111);
      // const IP = environment === 'TE' ? 'https://ivatest.terabits.cn' : 'https://zjiva.terabits.cn'
      // // const type = localStorage.getItem('type')
      // // if (type) {
      // // window.open(`http://ldiva.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // // } else {
      // window.open(`${IP}/app/hotels?token=${this.$store.getters.token}`)
      window.open(`https://child.terabits.cn/unit?token=${this.$store.getters.token}`)
    }
  }
};
</script>

<style scoped lang="scss">
.cityheader {
  // width: 100%;
  height: 107px;
  background: url("../../../../assets/infant/header.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  .center {
    p {
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 2px;
      color: #e8effb;
      line-height: 46px;
      background: linear-gradient(0deg, #b2ccfc 0%, #ffffff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .time {
    display: flex;
    align-items: center;
    position: absolute;
    top: 35%;
    right: 22%;
    > span {
      display: block;
      width: 28px;
      height: 27px;
      img {
        width: 100%;
      }
    }
    p {
      font-size: 16px;
      color: #00ffea;
      font-weight: bold;
      span {
        margin-left: 11px;
      }
    }
  }
  .right {
    position: absolute;
    top: 20%;
    right: 1%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #fdfdfd;
    .goback {
      cursor: pointer;
      margin-left: 24px;
      background: url("../../../../assets/infant/back.png") no-repeat;
      background-size: 100% 100%;
      width: 81px;
      height: 30px;
      line-height: 30px;
      color: #ffffff;
      text-align: right;
      padding-right: 8px;
      box-sizing: border-box;
    }
  }
}
</style>
