<template>
  <div class='infant'>
    <header-top ref="headers" />
    <div class="content">
      <left ref="venues" />
      <center />
      <right ref="unittotal" />
    </div>
  </div>
</template>

<script>
import HeaderTop from './components/header'
// import CenterBottom from './components/centerBottom'
import Center from './components/center'
// import LeftBottom from './components/leftBottom'
import Left from './components/left'
import Right from './components/right'
// import RightTop from './components/rightTop'
import { getInfant } from '@/api'
export default {
  // components: { HeaderTop, CenterBottom, CenterTop, RightBottom, RightTop, LeftBottom, LeftTop },
  components: { HeaderTop, Left, Center, Right },
  data () {
    return {

    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getInfant().then(res => {
        // console.log('res :>> ', res);
        const { data } = res

        // this.$refs.percentage.init(data)
        const rightMsg = {
          districtSum: data.districtSum,
          unitSum: data.unitSum,
          deviceSum: data.deviceSum
        }
        this.$refs.venues.init(rightMsg, JSON.parse(JSON.stringify(data.unitAndWorkInfoVOS)), data.areaName)
        this.$refs.unittotal.init(res.data)
        this.$refs.headers.init(data.areaName)
      })
      // getEnter().then(res => {
      //   // this.$store.commit("SET_ENTER", res.data)
      //   this.$refs.leftTop.getName(JSON.parse(JSON.stringify(res.data)))
      //   this.$refs.centerTop.init(JSON.parse(JSON.stringify(res.data)))
      //   this.$refs.leftBottom.init(res.data.filter(el => el.type === 'asian')[0])
      // })
    }
  }
};
</script>

<style scoped lang="scss">
.infant {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
  background: url("../../assets/city/bg.png") no-repeat;
  background-size: 100% 100%;
  .content {
    width: 100%;
    padding: 1px 24px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    // .center {
    //   flex: 1;
    // }
  }
}
</style>
