<template>
  <div class="percentage">
    <el-row class="infantcaption" :gutter="10">
      <el-col :span="6">场馆名称</el-col>
      <el-col :span="3">所属区域</el-col>
      <el-col :span="3">异常等级</el-col>
      <el-col :span="7">异常类型</el-col>
      <el-col :span="5">发生时间</el-col>
    </el-row>
    <div class="swiper swiper_alarmInfant " v-if="alarmList.length>0">
      <div class="swiper-wrapper">
        <el-row v-for="(el, index) in alarmList" class="swiper-slide" :key="index" :gutter="10">
          <el-col :span="6">{{el.unitName}}</el-col>
          <el-col :span="3">{{el.area}}</el-col>
          <el-col :span="3" style="color:#1BFD2A">{{el.level===1?'低':el.level===2?'中':'高'}}</el-col>
          <el-col :span="7">{{el.message}}</el-col>
          <el-col :span="5">{{el.gmtCreate}}</el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css'
Swiper.use([Autoplay])
import { getAlarmInfant } from '@/api'
export default {
  data () {
    return {
      alarmList: []
    };
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      getAlarmInfant().then(res => {
        // console.log('res :>> ', res);
        this.alarmList = res.data.alarmHistoryVOList
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.alarmList && this.alarmList.length > 6) {
          new Swiper('.swiper_alarmInfant', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 3000
          })
        }
        if (this.alarmList && this.alarmList.length <= 6) {
          new Swiper('.swiper_alarmInfant', {
            slidesPerView: 'auto',
            direction: 'vertical',
            speed: 3000
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.percentage {
  width: 100%;
  // margin-top: 30px;
  // height: 334px;
  // padding: 0 60px;
  .infantcaption {
    font-size: 16px;
    font-weight: 500;
    color: #15eaff;
    .el-col {
      text-align: center;
    }
  }
  // box-sizing: border-box;
  .swiper_alarmInfant {
    overflow: hidden;
    height: 200px;
    margin-top: 23px;
    .swiper-slide {
      height: 16px;
      // padding: 10px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 20px;
      .el-col {
        text-align: center;
      }
      .el-col-5,
      .el-col-6,
      .el-col-7 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
      .el-col-5 {
        text-align: center;
      }
    }
  }
}
</style>
