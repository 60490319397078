<template>
  <div class='infant_center'>
    <center-top :airDeviceAverageOnlineRate="airDeviceAverageOnlineRate"/>
    <Map ref="mapdata" />
    <center-bottom ref="percentage" />
  </div>
</template>

<script>
import Big from 'big.js'
import { getInfant } from '@/api'
import Map from './map.vue'
import CenterBottom from './centerBottom.vue'
import CenterTop from './centerTop.vue'
// import AMap from 'AMap'
// import { mapGetters } from 'vuex'

export default {
  components: { Map, CenterBottom, CenterTop },
  data() {
    return {
      centerData: {},
      airDeviceAverageOnlineRate:new Big(0)
    };
  },
  created() {
    this.init()
  },
  mounted() {

  },
  // computed: {
  //   ...mapGetters(['name'])
  // },
  methods: {
    init() {
      // this.centerData = data
      // 获取酒店分布
      getInfant().then(res => {
        this.airDeviceAverageOnlineRate = new Big(res.data.airDeviceAverageOnlineRate || 0)
        this.airDeviceAverageOnlineRate=this.airDeviceAverageOnlineRate.times(100)
        res.data.unitAndWorkInfoVOS.map(el => {
          if (el.modules) {
            const arr = JSON.parse(el.modules)
            el.testItem = []
            arr.map(item => {
              switch (item) {
                case 1:
                  el.testItem.push('水质监测')
                  break;
                case 2:
                  el.testItem.push('消毒监测')
                  break;
                case 3:
                  el.testItem.push('空气监测')
                  break;
                case 4:
                  el.testItem.push('扫码管理')
                  break;
                case 5:
                  el.testItem.push('视频监测')
                  break;
              }
            })
          }
        })
        // this.$refs.percentage.init(res.data)
        this.$refs.mapdata.initList(res.data.areaRunningInfos, res.data.unitAndWorkInfoVOS, res.data.areaName)
      })
    }
  }
};
</script>

<style scoped lang="scss">
.infant_center {
  // width: 100%;
  // flex-grow: 1;
  flex-shrink: 0;
  width: 808px;
  padding: 0 15px;
  box-sizing: border-box;
  // height: 629px;
}
</style>
