<template>
  <div>
    <ul class="total_data" v-if="Object.keys(totalData)&&Object.keys(totalData).length>0">
      <li v-if="areaName.indexOf('省') !== -1">
        <p><img src="../../../../assets/infant/pt.png" alt=""></p>
        <div>
          <!-- <countTo :startVal='0' :endVal='totalData.districtSum' :duration='2000' separator="" /> -->
          <span>{{totalData.districtSum}}<i></i></span>
          <p>区县</p>
        </div>
      </li>
      <li>
        <p><img src="../../../../assets/infant/site.png" alt=""></p>
        <div>
          <!-- <countTo :startVal='0' :endVal='totalData.unitSum' :duration='2000' separator="" /> -->
          <!-- <span>{{totalData.unitSum}}<i>/{{areaName.indexOf('省') !== -1?30:5}}</i></span> -->
          <span>{{totalData.unitSum}}<i></i></span>
          <p>场所</p>
        </div>
      </li>
      <li>
        <p><img src="../../../../assets/infant/equipment.png" alt=""></p>
        <div>
          <!-- <h3>{{totalData.deviceSum}}</h3> -->
          <!-- <countTo :startVal='0' :endVal='totalData.deviceSum' :duration='2000' separator="" /> -->
          <span>{{totalData.deviceSum}}</span>
          <p>设备数</p>
        </div>
      </li>
      <li>
        <p><img src="../../../../assets/infant/day.png" alt=""></p>
        <div>
          <!-- <h3>407</h3> -->
          <!-- <countTo :startVal='0' :endVal='dayTime' :duration='2000' separator="" /> -->
          <span>{{dayTime}}</span>
          <p>运行天数</p>
        </div>
      </li>
    </ul>
    <div class="swiper swiper_venues" v-if="unitList.length>0">
      <div class="swiper-wrapper">
        <div class='infant_left swiper-slide' v-for="(item,index) in unitList" :key="index">
          <div class="top">
            <p class="headline">
              场馆信息
              <span>{{item.cityName}}-{{item.districtName}}</span>
            </p>
            <ul>
              <li>
                <p>单位名称：</p>
                <span>{{item.unit}}</span>
              </li>
              <li>
                <p>单位地址：</p>
                <span>{{item.address}}</span>
              </li>
              <li>
                <p>法人姓名：</p>
                <span>{{item.userName}}</span>
              </li>
              <li>
                <p>联系电话：</p>
                <span>{{item.phone}}</span>
              </li>
            </ul>
          </div>
          <div class="center">
            <p class="headline">空气质量实时监测数据</p>
            <ul>
              <li>
                <h3>{{item.humidity?item.humidity:0}}</h3>
                <p>湿度 (RH)</p>
              </li>
              <li>
                <h3>{{item.temperature?item.temperature:0}}</h3>
                <p>温度 (℃)</p>
              </li>
            </ul>
            <div class="item">
              <p>
                <span>CO₂</span>
                <span>(ppm)</span>
              </p>
              <div>
                <p :style="{width:item.coPercent>100?'100%':`${item.coPercent}%`,color:item.coPercent<=100?'#FFFFFF':'#D44646'}"></p>
                <span>{{item.co2}}</span>
                <!-- <i v-if="item.co2 !== 0"></i> -->
              </div>
            </div>
            <div class="item">
              <p>
                <span>VOCs</span>
                <span>(ppb)</span>
              </p>
              <div>
                <p :style="{width:item.tvocPercent>100?'100%':`${item.tvocPercent}%`,color:item.tvocPercent<=100?'#FFFFFF':'#D44646'}"></p>
                <span>{{item.tvoc}}</span>
                <!-- <i v-if="item.tvoc !== 0"></i> -->
                <!-- <span style="width:20%"></span> -->
                <!-- <i style="background: #D44646;"></i> -->
              </div>
            </div>
            <div class="item">
              <p>
                <span>甲醛</span>
                <span>(ppm)</span>
              </p>
              <div>
                <p :style="{width:item.hchoPercent>100?'100%':`${item.hchoPercent}%`,color:item.hchoPercent<=100?'#FFFFFF':'#D44646'}"></p>
                <span>{{item.hcho}}</span>
                <!-- <i v-if="item.hcho !== 0"></i> -->
              </div>
            </div>
          </div>
          <div class="bottom">
            <p class="headline">运行数据</p>
            <ul>
              <li>
                <h4>视频监测<span><img v-if="item.module.indexOf(5) !== -1" src="../../../../assets/infant/check.png" alt=""></span></h4>
                <p v-if="item.module.indexOf(5) !== -1" :style="{color:item.cameraDeviceStatus?'#1af829':'#EF4864'}">{{item.cameraDeviceStatus?'在线':'离线'}}</p>
              </li>
              <li>
                <h4>消毒监测<span><img v-if="item.module.indexOf(2) !== -1" src="../../../../assets/infant/check.png" alt=""></span></h4>
                <p :style="{color:item.disinfectDeviceStatus?'#1af829':'#EF4864'}" v-if="item.module.indexOf(2) !== -1">{{item.disinfectDeviceStatus?'在线':'离线'}}</p>
              </li>
              <li>
                <h4>年度自查</h4>
                <p>已完成</p>
              </li>
              <li>
                <h4>体温检测<span><img v-if="item.module.indexOf(4) !== -1" src="../../../../assets/infant/check.png" alt=""></span></h4>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import countTo from 'vue-count-to';
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css'
Swiper.use([Autoplay])
export default {
  // components: { countTo },
  data () {
    return {
      unitList: [],
      totalData: {},
      dayTime: null,
      areaName: ''
    };
  },
  created () {

  },
  mounted () {
    // this.getName()
  },
  // computed: {
  //   ...mapGetters(['name'])
  // },
  methods: {
    init (totalData, unitList, areaName) {
      this.areaName = areaName
      this.totalData = totalData
      const nowTime = this.$moment(new Date(), 'YYYY-MM-DD')
      const startTime = this.$moment('2022-11-1', 'YYYY-MM-DD')
      this.dayTime = this.$moment(nowTime).diff(startTime, 'day')
      this.unitList = unitList.map(el => {
        if (el.co2) {
          el.coPercent = el.co2 / 1500 * 100
        } else {
          el.co2 = 0
          el.coPercent = 0
        }
        if (el.tvoc) {
          el.tvocPercent = el.tvoc / 0.5 * 100
        } else {
          el.tvoc = 0
          el.tvocPercent = 0
        }
        if (el.hcho) {
          el.hchoPercent = el.hcho / 0.1 * 100
        } else {
          el.hcho = 0
          el.hchoPercent = 0
        }
        if (el.modules) {
          el.module = JSON.parse(el.modules)
        } else {
          el.module = []
        }
        return el
      })
      this.initSwiper()
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.unitList && this.unitList.length > 1) {
          new Swiper('.swiper_venues', {
            autoplay: {
              delay: 10000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            slidesPerView: 'auto',
            loop: true,
            speed: 3000
          })
        }
        if (this.unitList && this.unitList.length <= 1) {
          new Swiper('.swiper_venues', {
            slidesPerView: 'auto',
            speed: 3000
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.total_data {
  display: flex;
  justify-content: space-around;
  margin: -30px 0 20px;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    > p {
      width: 80px;
      height: 80px;
      background: url("../../../../assets/infant/total_bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40%;
      }
    }
    > div {
      // padding: 9px 23px;
      margin-top: 11px;
      width: 100px;
      padding: 9px 0;
      background: url("../../../../assets/infant/total_title.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      span {
        display: block;
        font-size: 32px;
        line-height: 1;
        font-weight: 500;
        color: #15eaff;
      }
      i {
        color: #ffffff;
      }
      p {
        font-size: 22px;
        line-height: 1;
        margin-top: 12px;
        color: #ffffff;
      }
    }
  }
}
.swiper_venues {
  width: 514px;
  flex-shrink: 0;
  .swiper-wrapper {
    width: 100%;
    .infant_left {
      width: 100%;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 45px 0 0 27px;
      .headline {
        background: url("../../../../assets/infant/title_box.png") no-repeat;
        background-size: 100% 100%;
        width: 448px;
        padding: 8px 0 8px 23px;
        box-sizing: border-box;
        font-size: 18px;
        color: #ffffff;
      }
      .top {
        .headline {
          padding: 0;
          padding-left: 23px;
          position: relative;
          span {
            display: inline-block;
            width: 146px;
            height: 44px;
            line-height: 44px;
            background: url("../../../../assets/infant/area.png") no-repeat;
            background-size: 100% 100%;
            font-size: 16px;
            color: #ffc21f;
            text-align: center;
            margin-left: 148px;
          }
        }
        ul {
          padding: 15px 0 0 11px;
          li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;
            font-size: 16px;
            color: #ffffff;
            p {
              // width: 20%;
              white-space: nowrap;
              color: #ffc21f;
            }
            span {
              display: inline-block;
              width: 70%;
              // line-height: 1.5;
            }
          }
        }
      }
      .center {
        padding-bottom: 5px;
        ul {
          margin: 22px 0 10px;
          display: flex;
          // padding-left: ;
          justify-content: center;
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 54px;
            background: url("../../../../assets/infant/humidity.png") no-repeat;
            background-size: 100% 100%;
            padding: 10px 18px 10px 19px;
            h3 {
              font-size: 42px;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 10px;
              text-shadow: 0 0 10px #1e7fda;
            }
            p {
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
            }
          }
          li:last-child {
            background: url("../../../../assets/infant/temperature.png")
              no-repeat;
            background-size: 100% 100%;

            h3 {
              text-shadow: 0 0 10px #b3884c;
            }
          }
        }
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          position: relative;
          > p {
            font-size: 14px;
            color: #ffffff;
            font-weight: bold;
            line-height: 14px;
            span {
              display: block;
            }
          }
          > div {
            width: 380px;
            margin-left: 10px;
            padding: 3px;
            border: 1px solid #00e4ff;
            border-radius: 13px;
            display: flex;
            align-items: center;
            // position: relative;
            p {
              background: linear-gradient(
                90deg,
                rgba(181, 71, 231, 0.2) 0%,
                #b547e7 100%
              );
              border-radius: 10px 10px 10px 10px;
              height: 20px;
              line-height: 20px;
              font-size: 24px;
              color: #ffffff;
              text-align: center;
              font-weight: bold;
            }
            > span {
              // position: absolute;
              line-height: 20px;
              font-size: 18px;
              color: #ffffff;
              text-align: center;
              font-weight: bold;
              margin-left: 10px;
              // right: 10px;
            }
          }
        }
        .item:nth-of-type(2) {
          > div {
            border: 1px solid #0179fe;
            p {
              background: linear-gradient(
                90deg,
                rgba(71, 241, 255, 0.2) 0%,
                #47f1ff 100%
              );
            }
          }
        }
        .item:nth-of-type(3) {
          > div {
            border: 1px solid #fee17b;
            p {
              background: linear-gradient(
                90deg,
                rgba(244, 200, 96, 0.2) 0%,
                #f4c860 100%
              );
            }
          }
        }
      }
      .bottom {
        ul {
          display: flex;
          flex-wrap: wrap;
          padding-top: 20px;
          li {
            // width: 220px;
            width: 45%;
            background: url("../../../../assets/infant/operating_data.png")
              no-repeat;
            background-size: 100% 100%;
            padding: 17px 0 11px 88px;
            box-sizing: border-box;
            margin-right: 23px;
            margin-bottom: 18px;
            h4 {
              font-size: 16px;
              color: #f9fafa;
              font-weight: bold;
              span {
                margin-left: 21px;
                display: inline-block;
                width: 11px;
                height: 11px;
                border: 2px solid #efefef;
                position: relative;
                img {
                  width: 19px;
                  height: 17px;
                  position: absolute;
                  left: 1px;
                  // bottom: 2px;
                  top: -5px;
                }
              }
            }
            p {
              font-weight: 600;
              font-size: 16px;
              color: #1af829;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
