<template>
  <div class='city_map'>
    <div class="back" @click="ehartsMapBack" v-if="backNumber !== 0">
      <i class="el-icon-back" style="color:#fff"></i>
    </div>
    <div id="cityMap"></div>
  </div>
</template>

<script>

// import { getAreaSite, getAreaList, getEnter } from '@/api'
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      echartsMap: null,
      map: null,
      geoJsonData: null,
      cityName: '浙江',
      mapData: [],
      cityCode: 330000,
      level: 'province',
      district: null,
      upCityName: '浙江',
      upLevel: 'province',
      upCode: 330000,
      city: [],
      backNumber: 0,
      hotelList: [],
      enterList: [],
      cityArea: [],
      // 地图弹窗三个图表
      county: null,
      venue: null,
      equipment: null,
      areaName: null,
      upperCityName: null,
      upperCityAdcode: null
    };
  },
  created () {

  },
  mounted () {
    // this.initList()
  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    // 获取初始数据
    async initList (areaData, unitData, areaName) {
      // 城市级别
      if (areaName === '玉环县') {
        this.areaName = '玉环市'
      } else if (areaName === '临安市') {
        this.areaName = '临安区'
      }
      else {
        this.areaName = areaName
      }

      this.upperCityName = areaData[0].cityName
      // 市级数量
      this.city = areaData
      const unitArr = await this.disposeUnit(unitData)
      // 所有场所
      let areaArr = await unitArr.reduce((obj, item) => {
        if (obj[item.districtName]) {
          obj[item.districtName].unit.push(item)
          obj[item.districtName].unitSum += 1
          obj[item.districtName].deviceSum += item.deviceQuantity
        } else {
          obj[item.districtName] = {
            districtName: item.districtName,
            unitSum: 1,
            deviceSum: item.deviceQuantity,
            unit: [item]
          }
        }
        return obj;
      }, {});
      this.cityArea = await Object.values(areaArr)
      // console.log('this.cityArea :>> ', this.cityArea);
      this.initMap()
    },
    // 处理场馆数据获取经纬度
    disposeUnit (unitData) {
      unitData.map(el => {
        if (el.districtName === '玉环县') {
          el.districtName = '玉环市'
        } else if (el.districtName === '临安市') {
          el.districtName = '临安区'
        }
        AMap.plugin('AMap.Geocoder', function () {
          var geocoder = new AMap.Geocoder({
            // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
            // city: '010'
          })

          geocoder.getLocation(el.address, function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              // console.log('result :>> ', result);
              el.longitude = result.geocodes[0].location.lng
              el.latitude = result.geocodes[0].location.lat
              el.adcode = result.geocodes[0].adcode
            }
          })
        })
      })
      // console.log('unitData :>> ', unitData);
      return unitData
    },
    // 初始化地图
    initMap () {
      let echartsMap = this.echartsMap
      let _this = this
      let map = this.map
      let district = this.district
      echartsMap = this.$echarts.init(document.getElementById('cityMap'));
      echartsMap.on('click', this.echartsMapClick);
      AMap.plugin('AMap.DistrictSearch', function () {
        district = new AMap.DistrictSearch({
          subdistrict: 1,
          showbiz: false
        })
        // console.log('_this.cityName :>> ', _this.cityName);
        // 搜索所有省/直辖市信息
        district.search(_this.upperCityName, function (status, result) {
          // 查询成功时，result即为对应的行政区信息
          if (status == 'complete') {
            _this.upperCityAdcode = result.districtList[0].adcode
          }
        })
        district.search(_this.areaName, function (status, result) {
          // 查询成功时，result即为对应的行政区信息
          if (status == 'complete') {
            // console.log('result :>> ', result);
            _this.cityName = result.districtList[0].name
            _this.cityCode = result.districtList[0].adcode
            _this.level = result.districtList[0].level
            _this.upCityName = result.districtList[0].name
            _this.upLevel = result.districtList[0].level
            _this.upCode = result.districtList[0].adcode
            _this.getData(result.districtList[0], '', result.districtList[0].adcode);
          }
        })
      })
      map = new AMap.Map('container', {
        resizeEnable: true,
        center: [116.30946, 39.937629],
        zoom: 3
      });
      this.echartsMap = echartsMap
      this.map = map
      this.district = district
    },
    getData (data, level, adcode) {
      // console.log(data, level, adcode);
      var subList = data.districtList;
      // let geoJsonData = this.geoJsonData
      // let cityName = this.cityName
      let mapData = this.mapData
      if (subList) {
        let curlevel = subList[0].level;
        // 如果为市
        if (curlevel === 'city') {
          mapData = []
          let city = JSON.parse(JSON.stringify(this.city))
          for (let i = 0, l = subList.length; i < l; i++) {
            let name = subList[i].name;
            let cityCode = subList[i].adcode;
            mapData.push({
              name: name,
              value: Math.random() * 100,
              cityCode: cityCode,
              level: curlevel
            });
          }
          let list = mapData.reduce((pre, cur) => {
            let target = pre.find(ee => cur.name.indexOf(ee.cityName) !== -1)
            if (target) {
              Object.assign(target, cur)
            } else {
              pre.push(cur)
            }
            return pre
          }, city)
          this.mapData = list
          this.loadMapData(adcode);
        } else if (curlevel === 'district') {
          // 如果为区
          mapData = []
          let cityArea = JSON.parse(JSON.stringify(this.cityArea))
          for (let i = 0, l = subList.length; i < l; i++) {
            let name = subList[i].name;
            let cityCode = subList[i].adcode;
            mapData.push({
              name: name,
              value: Math.random() * 100,
              cityCode: cityCode,
              level: curlevel
            });
          }
          // console.log('this.cityArea :>> ', this.cityArea);
          let list = mapData.reduce((pre, cur) => {
            let target = pre.find(ee => cur.name === ee.districtName)
            if (target) {
              Object.assign(target, cur)
            } else {
              pre.push(cur)
            }
            return pre
          }, cityArea)
          this.mapData = list
          this.loadMapData(adcode);
        } else if (curlevel === 'street') {
          if (this.geoJsonData) {
            let mapJsonList = this.geoJsonData.features;
            let mapJson = {};
            mapData = []
            let cityArea = JSON.parse(JSON.stringify(this.cityArea))
            for (let i in mapJsonList) {
              if (mapJsonList[i].properties.name == this.cityName) {
                mapJson.type = "FeatureCollection";
                mapJson.features = [].concat(mapJsonList[i]);
              }
            }
            mapData = []
            const filterData = cityArea.filter(el => el.districtName === this.cityName)[0]
            mapData.push({ name: this.cityName, value: Math.random() * 100, level: curlevel, cityCode: adcode, ...filterData });
            this.mapData = mapData
            this.loadMap(this.cityName, mapJson)
          } else {
            let map = this.map
            // let geoJsonData = this.geoJsonData
            // let cityName = this.cityName
            AMapUI.loadUI(['geo/DistrictExplorer'], DistrictExplorer => {
              //创建一个实例
              var districtExplorer = window.districtExplorer = new DistrictExplorer({
                eventSupport: true, //打开事件支持
                map: map
              });

              districtExplorer.loadAreaNode(this.upperCityAdcode, (error, areaNode) => {
                // debugger
                if (error) {
                  console.error(error);
                  return;
                }
                let mapJson = {};
                let mapJsonList = areaNode.getSubFeatures();
                mapData = []
                let cityArea = JSON.parse(JSON.stringify(this.cityArea))
                for (let i in mapJsonList) {
                  if (mapJsonList[i].properties.name == this.cityName) {
                    mapJson.type = "FeatureCollection";
                    mapJson.features = [].concat(mapJsonList[i]);
                  }
                }
                const filterData = cityArea.filter(el => el.districtName === this.cityName)[0]
                mapData.push({ name: this.cityName, value: Math.random() * 100, level: curlevel, cityCode: adcode, ...filterData });
                this.mapData = mapData
                this.loadMap(this.cityName, mapJson)
              });
            });
          }
        }
      }
    },
    // 渲染地图
    loadMap (mapName, data) {
      let echartsMap = this.echartsMap
      // let mapData = this.mapData
      // let hotelList = this.hotelList
      let dotList = []
      // console.log('this.mapData :>> ', this.mapData);
      if (this.mapData[0].level === 'street') {
        let arr
        if (this.mapData[0].unit && this.mapData[0].unit.length > 0) {
          arr = JSON.parse(JSON.stringify(this.mapData[0].unit))
          arr.map(el => {
            dotList.push({
              name: el.unit,
              value: [el.longitude, el.latitude],
              ...el
            })
          })
        }
        // console.log('mapData :>> ', this.mapData);
        // const arr = hotelList.filter(el => el.adcode === mapData[0].cityCode)
        // if (arr.length > 0) {
        //   if (arr[0].siteList && arr[0].siteList.length > 0) {
        //     arr[0].siteList.map(el => {
        //       dotList.push({
        //         name: el.sitename,
        //         value: [el.longitude, el.latitude],
        //         phone: el.phone,
        //         roomQuantity: el.roomQuantity,
        //         cameraQuantity: el.cameraQuantity,
        //         boardQuantity: el.boardQuantity
        //       })
        //     })
        //   }
        // }
      }
      const _this = this
      if (data) {
        this.$echarts.registerMap(mapName, data);
        var option = {
          tooltip: {
            show: true,
            trigger: 'item',
            // position: function (pos, params, dom, rect, size) {
            //   // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            //   var obj = { top: pos[1] };
            //   obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            //   return obj;
            // },
            formatter: function (params) {
              let mapArr = []
              if (params.data.level === 'street') return
              // let number = 0
              // if (params.data.count) {
              //   number = params.data.count
              // }
              if (params.data.level === 'district') {
                mapArr = [
                  {
                    id: 'venuepie',
                    number: params.data.unitSum ? params.data.unitSum : 0,
                    name: '场馆'
                  },
                  {
                    id: 'equipmentpie',
                    number: params.data.deviceSum ? params.data.deviceSum : 0,
                    name: '设备数'
                  }
                ]
              } else {
                mapArr = [
                  {
                    id: 'countypie',
                    number: params.data.districtSum ? params.data.districtSum : 0,
                    name: '区县'
                  },
                  {
                    id: 'venuepie',
                    number: params.data.unitSum ? params.data.unitSum : 0,
                    name: '场馆'
                  },
                  {
                    id: 'equipmentpie',
                    number: params.data.deviceSum ? params.data.deviceSum : 0,
                    name: '设备数'
                  }
                ]
              }

              setTimeout(() => {
                _this.drawPie(mapArr)
              }, 100)
              return `
                <div class="maptooltip_infant">
          <h3>${params.data.name}</h3>
          <p class="headline">
          <i></i>
          <span>运行情况</span>
           </p>
          <div class="tooltipmsg">
          ${params.data.level === 'district' ? '' : '<div id="countypie"></div>'}
            
            <div id="venuepie"></div>
            <div id="equipmentpie"></div>
          </div>
          <div class="tooltiptitle">
            ${params.data.level === 'district' ? '' : '<p>区县</p>'}
            <p>场馆</p>
            <p>设备数</p>
          </div>
          </div>
              `
            },
            backgroundColor: 'rgba(14,33,60, 0)',
            borderColor: 'rgba(40, 86, 148, 0)',
            padding: 0
          },
          /*backgroundColor: '#000f1e',*/
          geo: {
            map: mapName,
            aspectScale: 1,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: '100%',
            itemStyle: {
              // normal: {
              shadowColor: '#1970B9',
              shadowOffsetX: 5,
              shadowOffsetY: 10,
              opacity: 0.5,
              // },
              emphasis: {
                areaColor: '#1970B9',

              }
            },

          },
          series: [
            // 常规地图
            {
              type: 'map',
              mapType: mapName,
              // roam: 'scale',、
              aspectScale: 1,
              layoutCenter: ["50%", "50%"], //地图位置
              layoutSize: '100%',
              zoom: 1, //当前视角的缩放比例
              scaleLimit: { //滚轮缩放的极限控制
                min: 1,
                max: 2
              },
              itemStyle: {
                // normal: {
                areaColor: '#103F5B',
                borderColor: '#29DDFE',
                borderWidth: 1.5,
                opacity: 1,
                shadowColor: 'RGBA(28, 137, 171, 0.5)',
                shadowBlur: 10,
                // },
                emphasis: {
                  areaColor: '#164C6A',
                  label: {
                    color: "#fff"
                  }
                }
              },
              label: {
                show: true,
                color: 'white',
                formatter: function (params) {
                  let number = 0
                  if (params.data.unitSum) {
                    number = params.data.unitSum
                  }
                  return '{count|' + number + '}\n{name|' + params.data.name + '}'
                },
                rich: {
                  count: {
                    color: '#FACC14',
                    fontSize: 20,
                    // lineHeight: 25
                  },
                  name: {
                    color: 'white',
                    fontSize: 14,
                    lineHeight: 20
                  }
                }
              },
              data: this.mapData,
              select: { // 地图选中区域样式
                label: { // 选中区域的label(文字)样式
                  color: '#fff'
                },
                itemStyle: {// 选中区域的默认样式
                  areaColor: '#10265B'
                },
              },
            },
            // 区域散点图
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              // showEffectOn: 'emphasis',
              zlevel: 2,
              symbolSize: 8,
              rippleEffect: { //坐标点动画
                period: 3,
                scale: 5,
                // brushType: 'stroke'
              },
              label: {
                // normal: {
                show: false,
                position: 'top',
                formatter: '{b}',
                color: '#b3e2f2',
                // fontWeight: "",
                fontSize: 12
                // }
              },


              itemStyle: { //坐标点颜色
                // normal: {
                show: true,
                color: '#87CEFA',
                shadowBlur: 20,
                shadowColor: '#fff',
                // },
                emphasis: {
                  areaColor: '#f00',
                  focus: 'series'
                }
              },
              data: dotList,
              tooltip: {
                show: true,
                formatter: function (params) {
                  return `
                <div class="tooltipInfant">
          <div class="city"><i></i>${params.name}</div>
          <div class="content">
          <div>
              <i></i>
              <p>
                设备数：
                <span> ${params.data.deviceQuantity} </span>
                个
              </p>
            </div>
            <div>
              <i></i>
              <p>
                检测项目：
                <span> ${params.data.testItem ? params.data.testItem.join(',') : ''} </span>
              </p>
            </div>
            <div>
              <i></i>
              <p>
                单位地址：
                <span> ${params.data.address} </span>
              </p>
            </div>
            <div>
              <i></i>
              <p>
                联系电话：
                <span> ${params.data.phone} </span>
              </p>
            </div>
          </div>
        </div>
              `
                  // position: [10, 10]
                }
              }
            },
          ]
        }
        echartsMap.setOption(option);
        this.echartsMap = echartsMap
      }
    },
    loadMapData (areaCode) {
      let map = this.map
      // let geoJsonData = this.geoJsonData
      // let cityName = this.cityName
      AMapUI.loadUI(['geo/DistrictExplorer'], DistrictExplorer => {
        //创建一个实例
        var districtExplorer = window.districtExplorer = new DistrictExplorer({
          eventSupport: true, //打开事件支持
          map: map
        });

        districtExplorer.loadAreaNode(areaCode, (error, areaNode) => {
          // console.log('object :>> ', areaCode, error, areaNode);
          // debugger
          if (error) {
            console.error(error);
            return;
          }
          let mapJson = {};
          mapJson.type = "FeatureCollection";
          console.log('areaNode.getSubFeatures() :>> ', areaNode.getSubFeatures());
          mapJson.features = areaNode.getSubFeatures();
          this.loadMap('浙江', mapJson);
          this.geoJsonData = mapJson;
        });
      });
    },
    // 地图点击事件
    echartsMapClick (params) {//地图点击事件
      if (params && params.data.level == 'street') return;
      let cityName = this.cityName
      let cityCode = this.cityCode
      let district = this.district
      let level = this.level
      let backNumber = this.backNumber
      this.upCityName = cityName,
        this.upCode = cityCode,
        this.upLevel = level

      //清除地图上所有覆盖物
      cityName = params.data.name;
      cityCode = params.data.cityCode;
      level = params.data.level
      district.setLevel(params.data.level); //行政区级别
      district.setExtensions('all');
      //行政区查询
      //按照adcode进行查询可以保证数据返回的唯一性
      district.search(cityCode, (status, result) => {
        if (status === 'complete') {
          this.getData(result.districtList[0], params.data.level, cityCode);
        }
      });
      backNumber = backNumber + 1
      this.cityName = cityName
      this.cityCode = cityCode
      this.district = district
      this.level = level
      this.backNumber = backNumber
    },
    // 地图返回事件
    ehartsMapBack () {
      let cityName = this.upCityName
      let cityCode = this.upCode
      let district = this.district
      let backNumber = this.backNumber
      //清除地图上所有覆盖物
      district.setLevel(this.upLevel); //行政区级别
      district.setExtensions('all');
      //行政区查询
      //按照adcode进行查询可以保证数据返回的唯一性
      district.search(cityName, (status, result) => {
        if (status === 'complete') {
          this.getData(result.districtList[0], this.upLevel, cityCode);
        }
      });
      backNumber = backNumber - 1
      // this.setState({
      //   cityName, cityCode, district, backNumber,
      //   upCityName: '浙江',
      //   upCode: 330000,
      //   upLevel: ''
      // })
      this.cityName = cityName
      this.cityCode = cityCode
      this.district = district
      this.backNumber = backNumber
      this.upCityName = '浙江'
      this.upCode = 330000
      this.upLevel = ''
    },
    // 绘制饼图
    drawPie (mapArr) {
      mapArr.map(el => {
        if (this[`${el.id}chart`] != null && this[`${el.id}chart`] != "" && this[`${el.id}chart`] != undefined) {
          this[`${el.id}chart`].dispose();//销毁
        }
        this[`${el.id}chart`] = this.$echarts.init(document.getElementById(el.id))
        // 绘制图表
        const option = {
          // backgroundColor: '#142468',
          series: [{
            type: 'pie',
            zlevel: 1,
            silent: true,
            radius: ['92%', '97%'],
            hoverAnimation: false,
            color: "#1F3267",
            // animation:false,    //charts3 no
            label: {
              // normal: {
              show: true,
              position: 'center',
              color: '#9A9EBA',
              formatter: `{total|${el.number}}`,
              emphasis: {//中间文字显示
                show: true,
              },
              rich: {
                total: {
                  fontSize: 18,
                  fontFamily: "微软雅黑",
                  color: '#FFFFFF',
                  lineHeight: 20
                },
                active: {
                  fontFamily: "微软雅黑",
                  fontSize: 14,
                  color: '#999DA8'
                },
                // }
              },

            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [1]
          },
          {
            type: 'pie',
            zlevel: 2,
            silent: true,
            radius: ['70%', '80%'],
            label: {
              normal: {
                show: false
              },
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: this._pie3()
          },
          {
            type: 'pie',
            zlevel: 3,
            silent: true,
            radius: ['65%', '60%'],
            label: {
              normal: {
                show: false
              },
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: this._pie2()
          },
          ]
        }
        option && this[`${el.id}chart`].setOption(option)
        window.addEventListener("resize", () => {
          this[`${el.id}chart`].resize();
        })
      })

    },
    _pie3 () {
      let dataArr = [];
      for (var i = 0; i < 100; i++) {
        if (i % 8 === 0) {
          dataArr.push({
            name: (i + 1).toString(),
            value: 200,
            itemStyle: {
              normal: {
                color: "#2AA2C0",
                borderWidth: 0,
                borderColor: "rgba(0,0,0,0)"
              }
            }
          })
        } else {
          dataArr.push({
            name: (i + 1).toString(),
            value: 5,
            itemStyle: {
              normal: {
                color: "rgba(0,0,0,0)",
                borderWidth: 0,
                borderColor: "rgba(0,0,0,0)"
              }
            }
          })
        }

      }
      return dataArr

    },
    _pie2 () {
      let dataArr = [];
      for (var i = 0; i < 100; i++) {
        if (i % 2 === 0) {
          dataArr.push({
            name: (i + 1).toString(),
            value: 10,
            itemStyle: {
              normal: {
                color: "rgba(42,162,192,0.5)",
                borderWidth: 0,
                borderColor: "rgba(0,0,0,0)"
              }
            }
          })
        } else {
          dataArr.push({
            name: (i + 1).toString(),
            value: 10,
            itemStyle: {
              normal: {
                color: "rgba(0,0,0,0)",
                borderWidth: 0,
                borderColor: "rgba(0,0,0,0)"
              }
            }
          })
        }

      }
      return dataArr

    }
  }
};
</script>

<style scoped lang="scss">
.city_map {
  width: 100%;
  height: 65%;
  // margin-top: 20px;
  position: relative;
  #cityMap {
    width: 100%;
    height: 100%;
  }
  .back {
    position: absolute;
    left: 40px;
    top: 10px;
    z-index: 1000;
    color: white;
    font-size: 16px;
    font-weight: 800;
    cursor: pointer;
  }
  .el-icon-back {
    font-size: 24px;
    cursor: pointer;
  }
}
</style>
